/* eslint-disable import/no-anonymous-default-export */
// =============================================================================
// Lang for Vietnamese
// ==================================

export default {
    App: {
        TITLE: "Sale Service",
    },

    Languages: {
        VI: "Tiếng Việt",
        EN: "Tiếng Anh",
    },

    Home: {
        TITLE: "Trang chủ",
    },

    PROFILE: {
        TITLE_MENU: "Thông tin cá nhân",
        ORGANIZATION_LIST: "Danh sách tổ chức",
        CREATE_ORGANIZATION: "Tạo mới tổ chức",
        DONT_CHOOSE: "Không chọn",
    },

    Common: {
        WEB: "Web",
        NEXT: "Tiếp theo",
        PREVIEW: "Xem trước",
        APP_MOBILE: "App Mobile",
        SELECT: "Chọn",
        COMMON: "Dùng chung",
        CONTINUE: "Tiếp tục",
        CANCEL: "Hủy",
        CANCELED: "Đã hủy",
        CANCEL_SELECT: "Bỏ chọn",
        DISPLAY: "Hiển thị",
        TOTAL: "Tổng",
        NOTIFICATION: "Thông báo",
        OK: "OK",
        SAVE: "Lưu",
        SAVE_TEMP: "Lưu tạm",
        COMPLETE: "Hoàn thành",
        WAIT_TEST: "Chờ kiểm tra",
        PROCESSING: "Đang xử lý",
        UPDATE: "Cập nhật",
        IN_ACTIVE: "Ngưng hoạt động",
        ACTIVE: "Đang hoạt động",
        EMPTY_DATA: "Chưa có dữ liệu",
        NO_DATA: "Không có dữ liệu",
        ADD_NEW: "Thêm mới",
        ADD_IMAGE: "Thêm hình ảnh",
        GO_BACK: "Trở về",
        EXIT: "Thoát",
        CREATE_NEW: "Tạo mới",
        VIEW_ALL: "Xem tất cả",
        EDIT: "Chỉnh sửa",
        LOCK: "Khóa",
        UNBLOCK: "Mở khóa",
        DELETE: "Xóa",
        REPLACE: "Thay thế",
        UPLOAD: "Tải lên",
        UPLOAD_IMAGE: "Tải ảnh lên",
        SEARCH: "Tìm kiếm",
        SEE: "Xem",
        ACTION: "Thao tác",
        MILLION: "Tr",
        BILLION: "Tỷ",
        UNCONFIRM: "Hủy bỏ",
        FILTER: "LỌC",
        FILTER_LIST: "Lọc danh sách",
        RESET: "Hủy lọc",
        TYPE: "Loại",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        CODE: "Mã",
        INPUT: "Nhập",
        INPUT_NOTE: "Nhập ghi chú",
        EMAIL: "Email",
        DAY: "Ngày",
        HOUR: "Giờ",
        // TABLE
        LABEL_DISPLAY_ROWS: "{0} - {1} trên {2}",
        DOWN: "Tải về",
        DETAIL: "Chi tiết",
        APPROVE: "Phê duyệt",
        REJECT: "Từ chối",
        GENDER: "Giới tính",
        TIME: "Thời gian",
        START_TIME: "Thời gian bắt đầu",
        END_TIME: "Thời gian kết thúc",
        DISABLE: "Vô hiệu hóa",
        STATUS: "Trạng thái",
        COPY: "Sao chép",
        IMPORT: "Import",
        EXPORT: "Export",
        CITY: "Thành phố",
        PROVINCE: "Tỉnh thành",
        SELECT_PROVINCE: "Chọn tỉnh thành",
        DISTRICT: "Quận/huyện",
        SELECT_DISTRICT: "Chọn quận/huyện",
        WARDS: "Phường/xã",
        SELECT_WARDS: "Chọn phường/xã",
        SEARCH_TEXT: "Tìm kiếm",
        ORGANIZATION: "Tổ chức",
        NO_ORGANIZATION: "Không thuộc tổ chức nào",
        LIST_ORGANIZATION: "Danh sách tổ chức",
        ACTION_WITH_EXCEL_FILE: "Thao tác excel",
        EXPORT_EXCEL: "Xuất excel",
        IMPORT_EXCEL: "Nhập excel",
        SELECT_FILE_FROM_DEVIDE: "Chọn tệp từ thiết bị",
        INVALID_DATA: "Dữ liệu không hợp lệ",
        FIELD_REQUIRED: "Trường bắc buộc",
        ACCEPT: "Đồng ý",
        ATTENTION: "Chú ý",
        MALE: "Nam",
        FEMALE: "Nữ",
        OTHER: "Khác",
        ALL: "Tất cả",
        CHANGE_STATUS: "Đổi trạng thái",
        CONFIRM: "Xác nhận",
        DATE_FORMAT: "vi",
        CLOSE: "Đóng",
        PRINT: "In",
        EXPORT_PDF: "Xuất PDF",
        ADDRESS_LINE: "Địa chỉ",
        ADDRESS_LINE_PHD: "Nhập địa chỉ",
        NAME: "Họ và tên",
        NOTE: "Ghi chú",
        BIRTHDATE: "Sinh nhật",
        APPLY: "Áp dụng",
        NOT_APPLY: "Ngừng áp dụng",
        VIEW: "Xem chi tiết",
        HERE: "Tại đây",
        UPLOAD_FILE: "Tải tệp lên",
        LOAD_MORE: "Xem thêm",
        USE_ALL_SYSTEM: "Sử dụng toàn hệ thống",
        USE: "Sử dụng",
        FAX_NUMBER: "Số Fax",
        TAX_NUMBER: "Số Tax",
        FROM_DATE: "Ngày bắt đầu",
        TO_DATE: "Ngày kết thúc",
        FROM: "Từ",
        TO: "Đến",
        REJECTED: "Đã từ chối",
        APPROVED: "Đã duyệt",
        WAITING: "Đang chờ duyệt",
        DRAFT: "Nháp",
        DELETED: "Đã xóa",
        BLOCKED: "Đã khóa",
        PAUSE: "Tạm dừng",
        YES: "Có",
        NO: "Không",
        IMAGE: "Hình ảnh",
        VIDEO: "Video",
        DOCUMENT: "Tài liệu",
        CHANGE: "Thay đổi",
        DROP_FILES_HERE: "Kéo thả file vào",
        CHOOSE_IMAGE: "Chọn ảnh",

        READ_MORE: "Xem thêm",
        COLLAPSE: "Thu gọn",
        EXPRIED: "Hết hạn",
        INFUTURE: "Chưa đến hạn",

        IndividualCustomer: "Khách hàng cá nhân",
        CompanyCustomer: "Khách hàng doanh nghiệp",
        CANCEL_ORDER: "Hủy đơn",
        SAVE_AND_PRINT: "Lưu và in",
        PRINT_ALL: "In tất cả",
        UNDER_MANAGEMENT: "Thuộc quản lý",
        SKIP: "Bỏ qua",
        UNREAD: "Chưa đọc",
        MARK_READ_ALL: "Đánh dấu đọc tất cả",
        LOADING: "Đang tải...",
        ADDRESS_INFO: "Thông tin địa chỉ",
        EXAMPLE: "VD",
        NO_NAME: "Không có tên",
    },

    TRANSACTION: {
        TITLE_MENU: "Giao dịch",
    },

    Message: {
        ERROR: "Đã có phát sinh lỗi trong quá trình xử lý, vui lòng thử lại sau. Xin lỗi vì đã gây bất tiện.",
        SUCCESS: "Thao tác hành công",
        CONFIRM_LOGOUT: "Bạn có muốn đăng xuất?",
        CONFIRM_DELETE: "Bạn có thực sự muốn xóa?",
        CONFIRM_CANCEL: "Bạn có thực sự muốn hủy?",
        CONFIRM_CANCEL_ACTIVE: "Bạn có thực sự muốn hủy kích hoạt?",
        CREATE_SUCCESS: "Tạo thành công",
        UPDATE_SUCCESS: "Cập nhật thành công",
        DELETE_SUCCESS: "Xóa thành công",
        CANCEL_SUCCESS: "Hủy thành công",
        CANCEL_ACTION_MESSAGE: "Thông tin chỉnh sửa chưa được lưu. Bạn chắc chắn muốn rời khỏi màn hình hiện tại không?",
        TOKEN_EXPIRED: "Hết hạn cho phiên đăng nhập! Vui lòng đăng nhập lại",
        NOT_FOUND: "Không tìm thấy dữ liệu!",
        NOT_PERMISSION: "Rất tiếc, bạn không có quyền truy cập",
        NO_ROLE_DELETE: "Bạn không có quyền xóa",
        DONT_HAVE_ORGANIZATION: "Để sử dụng chức năng này vui lòng tạo hoặc ",
        ORDER_AFTER_CANCELIATION_CANNOT_BE_RETURNED: "Đơn hàng sau khi hủy sẽ không thể lấy lại được!.",
        PLEASE_ENTER_THE_DISCOUNT_VALUE: "Vui lòng điền giá trị chiết khấu",
        CORRESPONDING_ORGANIZATION: " tổ chức tương ứng.",
    },

    Validation: {
        EMAIL_ADDRESS: "Sai định dạng email",
        REQUIRED: "Trường dữ liệu là bắt buộc",
        REQUIRED_ORGANIZATION: "Vui lòng chọn tổ chức",
        ORDER_ITEM_REQUIRED: "Vui lòng chọn sản phẩm",
        REQUIRED_CUSTOMER: "Vui lòng chọn khách hàng",
        FILTER_REQUIRED: "Vui lòng điền đầy đủ thông tin lọc",
    },

    Login: {
        LOGOUT: "Đăng xuất",
        USER_INFO: "Thông tin tài khoản",
    },

    Codename: {
        TITLE: "Danh mục dùng chung",
        TITLE_LIST: "Danh sách danh mục",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin danh mục",
        TITLE_DETAIL_VIEW: "Thông tin danh mục",
        TITLE_CREATE_VIEW: "Thêm mới danh mục",
        NAME: "Tên danh mục",
    },

    ATTRIBUTE: {
        TITLE_MENU: "Quản lý thuộc tính",
        TITLE_LIST_VIEW: "Danh sách thuộc tính",
        TITLE_CREATE_VIEW: "Thêm thuộc tính mới",
        TITLE_UPDATE_VIEW: "Cập nhật thuộc tính",
        TITLE_DETAIL_VIEW: "Thông tin thuộc tính",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên",
        NAME: "Tên thuộc tính",
        INPUT_NAME: "Nhập tên thuộc tính",
        VALUE_TYPE: "Kiểu giá trị",
        STRING: "Kiểu chuỗi",
        NUMBER: "Kiểu số",
        MULTI_LANGUAGE: "Đa ngôn ngữ",
        VALUE: "Giá trị thuộc tính",
        INPUT_VALUE: "Nhập giá trị thuộc tính",
        UPLOAD_FILES: "Tệp đính kèm",
    },

    PRODUCT_MANAGEMENT: {
        TITLE_MENU: "Quản lý sản phẩm",
    },

    PRODUCT: {
        TITLE_MENU: "Danh sách sản phẩm",
        TITLE_LIST: "Danh sách sản phẩm",
        TITLE_CREATE_VIEW: "Thêm sản phẩm mới",
        TITLE_UPDATE_VIEW: "Cập nhật sản phẩm",
        TITLE_DETAIL_VIEW: "Xem chi tiết sản phẩm",
        PRODUCT: "Mẫu sản phẩm",
        VALUE: "Đơn vị tiền tệ",
        INPUT_VALUE: "Nhập đơn vị tiền tệ",
    },

    PRODUCT_TEMPLATE: {
        TITLE_MENU: "Mẫu sản phẩm",
        TITLE_LIST: "Danh sách mẫu sản phẩm",
        TITLE_CREATE_VIEW: "Thêm mẫu sản phẩm mới",
        TITLE_UPDATE_VIEW: "Cập nhật mẫu sản phẩm",
        TITLE_DETAIL_VIEW: "Xem chi tiết mẫu sản phẩm",
        ORGANIZATION: "Tổ chức",
        SELECT_ORGANIZATION: "Chọn tổ chức",
        PRODUCT_TITLE: "Mẫu sản phẩm",
    },

    PRODUCT_BLUEPRINT: {
        TITLE_MENU: "Mẫu thiết kế",
        TITLE_LIST: "Danh sách mẫu thiết kế",
        TITLE_CREATE_VIEW: "Thêm mẫu thiết kế mới",
        TITLE_UPDATE_VIEW: "Cập nhật mẫu thiết kế",
        TITLE_DETAIL_VIEW: "Xem chi tiết mẫu thiết kế",
        ORGANIZATION: "Tổ chức",
        SELECT_ORGANIZATION: "Chọn tổ chức",
        PRODUCT_TITLE: "Mẫu thiết kế",
    },

    ORDER_TABE_HEADER: {
        SEQUENCE: "STT",
        IMAGE: "Hình ảnh",
        NAME: "Tên sản phẩm",
        NAME_SHORT: "Tên SP",
        QUANTITY: "Số lượng",
        PRICE: "Đơn giá",
        AMOUNT: "Thành tiền",
        DISCOUNT: "Giảm giá",
        INVENTORY: "Tồn kho",
    },

    ORDER: {
        TITLE_MENU: "Quản lý đơn hàng",
        TITLE_CREATE_ORDER: "Tạo đơn hàng",
        TITLE_LIST_VIEW: "Danh sách đơn hàng",
        TITLE_CREATE_VIEW: "Thêm đơn hàng mới",
        TITLE_UPDATE_VIEW: "Cập nhật đơn hàng",
        TITLE_DETAIL_VIEW: "Xem chi tiết đơn hàng",
        SEARCH_TEXT: "Tìm kiếm theo mã đơn hàng/ tên sản phẩm",
        ENTER_SEARCH_TEXT: "Nhập mã đơn hàng",
        CODE: "Mã đơn hàng",
        PURCHASE_TIME: "Thời gian mua hàng",
        CUSTOMER: "Khách hàng",
        SELECT_CUSTOMER: "Khách hàng",
        SELLER: "Người bán hàng",
        SELECT_SELLER: "Người bán hàng",
        TOTAL_VALUE: "Tổng giá trị đơn hàng",
        STATUS: "Trạng thái đơn hàng",
        PAYMENT_STATUS: "Trạng thái thanh toán",
        SELECT_STATUS: "Chọn trạng thái đơn hàng",
        GUEST: "Khách lẻ",
        VOUCHER: "Chiết khấu/Voucher",
        VOUCHER_TYPE: "Hình thức chiết khấu",
        VOUCHER_VALUE: "Giá trị chiết khấu",
        VOUCHER_COCE: "Mã giảm giá(Voucher)",
        DISCOUNT_CODE: "Mã giảm giá",
        DISCOUNT_CODE_INPUT: "Mã giảm giá",
        PROMOTION_PROGRAM: "Chương trình khuyến mãi",
        PROMOTION_ITEM: "Hàng khuyến mãi",
        CASH: "Tiền mặt",
        PERCENT: "%",
        SEARCH: "Thêm sản phẩm",
        ORDER_CODE: "Mã đơn hàng",
        SALES_AGENT: "Nhân viên bán hàng",
        PHONE_NUMBER: "Số điện thoại",
        SEQUENCY: "STT",
        IMAGE: "Hình ảnh",
        PRODUCT_CODE: "Mã sản phẩm",
        PRODUCT_NAME: "Tên sản phẩm",
        QUANTITY: "Số lượng",
        PRICE: "Đơn giá",
        TOTAL: "Thành tiền",
        DETAIL_PRODUCT: "Chi tiết sản phẩm",
        TOTAL_PRODUCT: "Tổng sản phẩm",
        TOTAL_PRICE: "Tổng tiền hàng",
        VAT_TAX: "Thuế",
        LOYALTY_CLASS_DISCOUNT: "Giảm giá thành viên",
        APPLY_PROMOTIONS: "Áp dụng chương trình khuyến mãi",
        ORDER_DISCOUNT: "Chiết khấu đơn hàng",
        USE_LOYALTY_POINT: "Sử dụng {0} điểm tích luỹ",
        TOTAL_AMOUNT_PAID_BY_CUSTOMER: "Tổng số tiền khách hàng thanh toán",
        PAYMENT_METHOD: "Phương thức thanh toán",
        NOTE: "Ghi chú",
        PAYMENT_HISTORY: "Lịch sử đơn hàng",
        CANCEL: "Huỷ đơn hàng",
        COMPLETE: "Hoàn thành hóa đơn",
        CREATE_ORDER: "Tạo đơn",
        PRINT: "In hoá đơn",
        ORDER_LIST: "ORDER_LIST",
        ITEM_LIST_TEMP: "ITEM_LIST_TEMP",
        VALUE: "Giá trị",
        VALUE_INPUT: "Nhập giá trị",
        THE_PRODUCT_HAS_BEEN_REMOVED: "Sản phẩm đã bị xóa khỏi hệ thống",
        TOTAL_MONEY_PAYMENT: "Tổng tiền thanh toán",
        ORDER_ONE: "Đơn 1",
        COMPLETE_ORDER: "Hoàn thành hóa đơn",
        BANKING: "Chuyển khoản ngân hàng",
        PAYPAL: "PAYPAL",
        VNPAY: "VNPAY",
        DOCUMENT: "Chứng từ",
    },

    STATUS: {
        ORDER: {
            Cancel: "Đã huỷ",
            Pending: "Đang chờ xử lý",
            New: "Mới",
            Confirm: "Xác nhận",
            Editing: "Chỉnh sửa",
            PaymentConfirm: "Xác nhận thanh toán",
            DebtConfirm: "Xác nhận nợ ",
            ExchangeRequest: "Yêu cầu đổi trả",
            ExchangeCancel: "Hủy đổi trả",
            Completed: "Hoàn thành",
        },
    },

    LOYALTY_CUSTOMER: {
        TITLE_MENU: "Khách hàng thân thiết",
    },

    CUSTOMER: {
        TITLE_MENU: "Quản lý khách hàng",
        TITLE_LIST: "Danh sách khách hàng",
        TITLE_CREATE_VIEW: "Thêm khách hàng mới",
        TITLE_UPDATE_VIEW: "Cập nhật khách hàng",
        TITLE_DETAIL_VIEW: "Chi tiết khách hàng",
        LOYALTY_CLASS: "Hạng thành viên",
        NOT_LOYALTY_CLASS: "Chưa có hạng thành viên",
        POINT: "Điểm tích luỹ",
    },

    PROMOTION: {
        TITLE_MENU: "Voucher / CTKM",
        TITLE_DETAIL: "Chi tiết CTKM",
        TITLE_UPDATE: "Cập nhật CTKM",
        TITLE_CREATE: "Tạo mới CTKM",
        TITLE_LIST_VIEW: "Danh sách Voucher / CTKM",
        SEARCH_TEXT: "Tìm kiếm theo mã chương trình",
        CODE: "Mã chương trình",
        NAME: "Tên CTKM",
        START_DAY: "Ngày hiệu lực",
        END_DAY: "Ngày hết hạn",
        APPLY_BRANCH: "Chi nhánh áp dụng",
        SELECT_APPLY_BRANCH: "Chi nhánh áp dụng",
        APPLY_OBJECT: "Đối tượng áp dụng",
        SELECT_APPLY_OBJECT: "Chọn đối tượng áp dụng",
        APPLY_BASE: "Cơ sở áp dụng",
        SELECT_APPLY_BASE: "Chọn cơ sở áp dụng",
        PROMOTION_METHOD: "Hình thức KM",
        SELECT_PROMOTION_METHOD: "Chọn Hình thức KM",
        APPLY_QUANTITY: "Số lượng KM áp dụng",
        REMAIN_QUANTITY: "Số lượng khuyến mãi còn lại",
        PROMATION_DETAIL: "Chi tiết CTKM",
        PROMATION_CONDITION: "Điều kiện khuyến mãi",
        PROMOTION_CODE: "Mã CTKM",
        ENTER_PROMOTION_CODE: "Nhập mã CTKM",
        PROMOTION_NAME: "Tên CTKM",
        ENTER_PROMOTION_NAME: "Nhập tên CTKM",
        START_TIME: "Ngày hiệu lực",
        SELECT_START_TIME: "Chọn ngày hiệu lực",
        END_TIME: "Ngày hết hạn",
        SELECT_END_TINE: "Chọn ngày hết hạn",
        UNKNOWN: "Không xác định",
        APPLY_ALL: "Áp dụng tất cả",
        PROGRESSIVE: "Khuyến mãi lũy tiến",
        PROGRESSIVE_NOTE: "(Nếu không check 1 đơn hàng chỉ được áp dụng 1 lần khuyến mãi)",
        ENTER_MAXIMUM_DISCOUNT: "Nhập số tiền giảm giá tối đa / 1 đơn",
        MAXIMUM_DISCOUNT: "Số tiền giảm giá tối đa / 1 đơn hàng",
        POINT_ON_TOTAL: "Điểm tặng tính trên tổng giá trị hoá đơn thanh toán",
        CRITERIA: "Tiêu chí",
        INVOICE_VALUE: "Giá trị hóa đơn",
        ENTER_INVOICE_VALUE: "Nhập giá trị hóa đơn từ",
        INVOICE_VALUE_FROM: "Giá trị hóa đơn từ",
        DISCOUNT_VALUE: "Số tiền giảm giá",
        ENTER_DISCOUNT_VALUE: "Nhập số tiền giảm giá",
        ADD_CRITERIA: "Thêm tiêu chí",
        AUTO_PRINT: "Tự động in voucher khi thanh toán",
        APPLY_MULTI_PROMOTION: "Áp dụng đồng thời nhiều chương trình khuyến mãi",
        GIFT_QUANTITY: "Số lượng hàng tặng",
        ENTER_GIFT_QUANTITY: "Nhập số lượng hàng tặng",
        GIFT_GOOD_GROUP: "Hàng / nhóm hàng tặng",
        SELECT_GIFT_GOOD_GROUP: "Chọn hàng / nhóm hàng tặng",
        GIFT_ITEM_QUANTITY_TOTAL: "Tổng số hàng tặng",
        ENTER_GIFT_ITEM_QUANTITY_TOTAL: "Nhập tổng số hàng tặng",
        ITEM_DISCOUNT_QUANTITY_TOTAL: "Tổng số hàng giảm giá",
        ENTER_ITEM_DISCOUNT_QUANTITY_TOTAL: "Nhập tổng số hàng giảm giá",
        GIFT_POINT_TOTAL: "Tổng số điểm được tặng",
        ENTER_GIFT_POINT_TOTAL: "Nhập tổng số điểm được tặng",
        MAXIMUM_POINT: "Số điểm tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_POINT: "Nhập tổng số điểm tối đa / 1 đơn hàng",
        MAXIMUM_GIFT_POINT: "Số lượng điểm tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GIFT_POINT: "Nhập số lượng điểm tặng tối đa / 1 đơn",
        GIFT_POINT: "Điểm tặng",
        ENTER_GIFT_POINT: "Nhập điểm tặng",
        PERCENT_GIFT_POINT: "% điểm tặng",
        ENTER_PERCENT_GIFT_POINT: "Nhập % điểm tặng",
        GIFT_VOUCHER_TOTAL: "Tổng số lượng voucher tặng",
        GIFT_VOUCHER_QUANTITY: "Số lượng voucher tặng",
        ENTER_GIFT_VOUCHER_QUANTITY: "Nhập số lượng voucher tặng",
        VOUCHER_RELEASE: "Đợt phát hành voucher",
        SELECT_VOUCHER_RELEASE: "Chọn đợt phát hành voucher",
        ENTER_GIFT_VOUCHER_TOTAL: "Nhập tổng số lượng voucher tặng",
        GOOD_BUY_GROUP: "Hàng / nhóm hàng mua",
        SELECT_GOOD_BUY_GROUP: "Chọn hàng / nhóm hàng mua",
        GIFT_PROMOTION_TOTAL: "Tổng số lượng hàng khuyến mãi",
        ENTER_GIFT_PROMOTION_TOTAL: "Nhập tổng số lượng hàng khuyến mãi",
        GOOD_BUY_QUANTITY: "Số lượng hàng mua",
        ENTER_GOOD_BUY_QUANTITY: "Số lượng hàng mua",
        GOOD_DISCOUNT_GROUP: "Hàng / nhóm hàng giảm giá",
        SELECT_GOOD_DISCOUNT_GROUP: "Chọn hàng / nhóm hàng giảm giá",
        GOOD_DISCOUNT_QUANTITY: "Số lượng hàng giảm giá",
        ENTER_GOOD_DISCOUNT_QUANTITY: "Nhập số lượng hàng giảm giá",
        PROMOTION_VALUE: "Số tiền khuyến mãi",
        ENTER_PROMOTION_VALUE: "Nhập số tiền khuyến mãi",
        PROMOTION_PERCENT: "% khuyến mãi",
        ENTER_PROMOTION_PERCENT: "Nhập % khuyến mãi",
        GIFT_GOOD_TOTAL: "Tổng số lượng hàng tặng",
        ENTER_GIFT_GOOD_TOTAL: "Nhập tổng số lượng hàng tặng",
        MAXIMUM_GIFT_GOOD: "Số lượng hàng tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GIFT_GOOD: "Nhập số lượng tặng tối đa / 1 đơn",
        ACCUMULATED_POINT: "Số điểm tích lũy tặng",
        ENTER_ACCUMULATED_POINT: "Nhập số điểm tích lũy tặng",
        ACCUMULATED_POINT_PERCENT: "% điểm tích lũy tặng",
        ENTER_ACCUMULATED_POINT_PERCENT: "Nhập % điểm tích lũy tặng",
        QUANTITY_BUY_FROM: "Số lượng mua từ",
        PROMOTION_PRICE: "Giá khuyến mãi",
        MAXIMUM_GOOD: "Số lượng hàng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GOOD: "Nhập số lượng hàng tối đa / 1 đơn",
        MAXIMUM_VOUCHER: "Số lượng voucher tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_VOUCHER: "Số lượng voucher tặng tối đa / 1 đơn",
        GIFT_GOOD: "Hàng tặng",
        DISCOUNT_GOOD: "Hàng giảm giá",
        DISCOUNT_GOOD_QUANTITY: "Số lượng hàng giảm giá",
        ENTER_DISCOUNT_GOOD_QUANTITY: "Nhập số lượng hàng giảm giá",
        DISCOUNT_PERCENT: "% giảm giá",
        ENTER_DISCOUNT_PERCENT: "Nhập % giảm giá",
        EXCLUDE_LOYALTY_POINT: "Áp dụng tích điểm thành viên",
        NEW_CRITERIA: "Tiêu chí mới",
        DISCOUNT_BY_VALUE: "Giảm giá theo số tiền",
        DISCOUNT_BY_PERCENT: "Giảm giá theo %",
        PROMOTION_BY_VALUE: "Khuyến mãi theo số tiền",
        PROMOTION_BY_PERCENT: "Khuyến mãi theo %",
        START_DATE_BIGGER_END_DATE: "Ngày hiệu lực phải nhỏ hơn ngày hết hạn",
        EXPENSE_START_DATE_BIGGER_EXPENSE_END_DATE: "Ngày ngày bắt đầu phải nhỏ hơn ngày kết thúc tính tổng chi tiêu tối thiểu",
        ITEM: "Hàng hóa",
        BILL: "Hóa đơn",
        BILL_AND_ITEM: "Hóa đơn và hàng hóa",
        BILL_DISCOUNT: "Giảm giá hóa đơn",
        GIFT_ITEM: "Tặng hàng",
        ITEM_DISCOUNT: "Giảm giá hàng",
        GIFT_LOYALTY_POINT: "Tặng điểm tích lũy",
        GIFT_VOUCHER: "Tặng voucher",
        ITEM_DISCOUNT_BY_QUANTITY: "Giá bán theo số lượng mua",
        VALUE: "Giá trị",
        UNIT: "Đơn vị",
        EXPIRED: "Đã hết hạn",
        VOUCHER: "Voucher / Coupon",
        PROMOTION: "CTKM",
        CANCEL: "Hủy kích hoạt",
        PRODUCT: "Sản phẩm",
        COMBO: "Combo",
        SERVICE: "Dịch vụ",
        NOT_USE: "Chưa sử dụng",
        USED: "Đã sử dụng",
        VIEW_LIST: "Xem danh sách",
        NOTE_ENTER_PAGE: "Vui lòng nhập số trang nhỏ hơn hoặc bằng",
        SEARCH_BY_VOUCHER_ID: "Tìm kiếm theo mã voucher",
    },

    VOUCHER: {
        TITLE_MENU: "Phát hành Voucher/ Coupon",
        RELASE_INFOMATION_TITLE: "Thông tin chung đợt phát hành",
        APPLICABILITY_RANGE_TITLE: "Phạm vi áp dụng",
        EMPLOYEE_LIST_TITLE: "Danh sách khách hàng đủ điều kiện",

        RELEASE_CODE: "Mã đợt phát hành",
        ENTER_RELEASE_CODE: "Nhập mã đợt phát hành",
        RELEASE_NAME: "Tên đợt phát hành",
        ENTER_RELEASE_NAME: "Nhập tên đợt phát hành",
        VOUCHER_VALUE: "Mệnh giá",
        VOUCHER_PERCENT: "Phần trăm",
        ENTER_VOUCHER_VALUE: "Nhập mệnh giá Voucher",
        ENTER_VOUCHER_PERCENT: "Nhập phần trăm",
        RELEASE_FORM: "Hình thức phát hành",
        VOUCHER: "Voucher",
        COUPON: "Coupon",
        IDENTIFICATION_VOUCHER: "Voucher định danh",
        APPLY_MULTIPLE_VOUCHERS_ON_ONE_BILL: "Áp dụng nhiều Voucher / Coupon trên 1 hoá đơn",
        ACTIVE: "Kích hoạt",

        EFFECTIVE_DATE: "Ngày hiệu lực",
        SELECT_EFFECTIVE_DATE: "Chọn ngày hiệu lực",
        EXPIRATION_DATE: "Ngày hết hạn",
        SELECT_EXPIRATION_DATE: "Chọn ngày hết hạn",
        APPLICABLE_OBJECT: "Đối tượng áp dụng",
        APPLICABLE_BRAND: "Chi nhánh áp dụng",
        APPLICABLE_GOODS: "Hàng/ nhóm hàng áp dụng",
        APPLICABLE_ITEMS: "Sản phẩm áp dụng",
        SELECT_APPLICABLE_OBJECT: "Chọn đối tượng áp dụng",
        SELECT_APPLICABLE_BRAND: "Chọn chi nhánh áp dụng",
        SELECT_APPLICABLE_GOODS: "Chọn hàng / nhóm hàng áp dụng",
        LOYALTY_CLASS: "Hạng thành viên",
        SELECT_LOYALTY_CLASS: "Chọn hạng thành viên",
        MINIMUN_TOTAL_TARGET: "Tổng chi tiêu tối thiểu",
        ENTER_MINIMUN_TOTAL_TARGET: "Nhập tổng chỉ tiêu tối thiểu",
        START_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Ngày bắt đầu tính tổng chỉ tiêu tối thiểu",
        END_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Ngày kết thúc tính tổng chỉ tiêu tối thiểu",
        SELECT_START_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Chọn ngày bắt đầu tính tổng chi tiêu tối thiểu",
        SELECT_END_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Chọn ngày kết thúc tính tổng chi tiêu tối thiểu",
        ADD_NEW_CONDITION: "Thêm mới điều kiện",
        CONDITION_NAME: "Tên điều kiện",
        ENTER_CONDITION_NAME: "Nhập tên điều kiện",
        VALUE: "Giá trị",
        ENTER_VALUE: "Nhập giá trị",
        APPLY_ALL: "Áp dụng tất cả",

        VOUCHER_LENGTH: "Độ dài mã voucher",
        START_CHARACTER: "Kí tự bắt đầu",
        NOTE: "Ghi chú",
        ENTER_VOUCHER_LENGTH: "Nhập độ dài mã voucher",
        ENTER_START_CHARACTER: "Nhập kí tự bắt đầu",
        ENTER_NOTE: "Nhập ghi chú",

        SEND_SMS: "Gửi SMS",
        PRINT_VOUCHER: "In voucher",
        RELEASE: "Phát hành",
        OTHER_ACTION: "Thao tác khác",
        CANCEL_RELEASE: "Huỷ phát hành",
        DELETE: "Xoá",

        QUALIFIED_QUANTITY: "Số lượng đủ điều kiện",
        RELEASE_QUANTITY: "Số lượng phát hành",
        ENTER_RELEASE_QUANTITY: "Nhập số lượng phát hành",

        CUSTOMER_CODE: "Mã khách hàng",
        CUSTOMER_NAME: "Tên khách hàng",
        CUSTOMER_PHONE_NUMBER: "Số điện thoại khách hành",
        TOTAL_SPENDING: "Tổng chi tiêu",
        VOUCHER_CODE: "Mã voucher",
        RELEASE_DATE: "Ngày phát hành",
        USE_DATE: "Ngày sử dụng",
        CREATE_CODE: "Tạo mã",
        RELEASE_NOW: "Phát tất cả hành ngay khi tạo voucher",
        BIRTHDAY: "Tháng sinh nhật",
        SELECT_MONTH: "Chọn tháng",
        SEARCH_TEXT: "Tìm kiếm theo tên/sku",
        ISSUE_ALL: "Phát hành tất cả",
        ISSUE_ALL_CONFIRM_MESSAGE: "Bạn có chắc chắn muốn phát hành toàn bộ voucher trong đợt phát hành?",
    },

    SYSTEM_SETTING: {
        TITLE_MENU: "Cấu hình hệ thống",
        TITLE_LIST_VIEW: "Danh sách mã hệ thống",

        CODE: "Mã",
        NAME: "Tên",
        VALUE: "Giá trị",
        DESCRIPTION: "Mô tả",
        UPDATE: "Cập nhật thông tin",
        DETAIL: "Chi tiết thông tin",

        ATR_COMMON: {
            Title: "Cấu hình đặc tính chung",
            Origin: "Mã xuất xứ",
            Brand: "Mã thương hiệu",
            CreateTenant: "Mã tạo người thuê",

            Enter_Origin: "Nhập mã xuất xứ",
            Enter_Brand: "Nhập mã thương hiệu",
            Enter_CreateTenant: "Nhập mã tạo người thuê",
        } as any,

        VNPAY_CONFIG: {
            Title: "Cấu hình VNPAY",
            TestUrl: "Test Url",
            ReturnUrl: "Return Url",
            Url: "Url",
            TmnCode: "Tmn Code",
            HashSecret: "Hash Secret",

            Enter_TestUrl: "Nhập Test Url",
            Enter_ReturnUrl: "Nhập Return Url",
            Enter_Url: "Nhập Url",
            Enter_TmnCode: "Nhập Tmn Code",
            Enter_HashSecret: "Nhập Hash Secret",
        } as any,

        PAYPAL_CONFIG: {
            Title: "Cấu hình PAYPAL",
            ClientId: "Clien tId",
            BaseURL: "Base URL",
            Secret: "Secret",

            Enter_ClientId: "Nhập Clien tId",
            Enter_BaseURL: "Nhập Base URL",
            Enter_Secret: "Nhập Secret",
        } as any,

        PAYMENT_CONFIG: {
            Title: "Cấu hình thanh toán",
            VNPay: "VNPay",
            Paypal: "Paypal",
            Checkout: "Checkout",
        } as any,

        PAYMENT_GATEWAY: {
            Title: "Cấu hình cổng thanh toán",
            Web: "Web",
            Ios: "Ios",
            Android: "Android",

            Name: "Tên cổng thanh toán",
            Credentials: "Thông tin xác thực",
            Tax: "Thuế",
            IsDefault: "Mặc định",

            Enter_Name: "Nhập tên cổng thanh toán",
            Enter_Credentials: "Nhập thông tin xác thực",
            Enter_Tax: "Nhập thuế",
        } as any,

        PARTNER_SETTING: {
            Title: "Cấu hình đối tác",
            Type: "Loại đối tác",
            Code: "Thuộc tính",
        } as any,
    } as any,

    MEMBERSHIP: {
        TITLE_MENU: "Danh mục gói thành viên",
        TITLE_LIST: "Danh sách gói thành viên",
        TITLE_CREATE_VIEW: "Thêm gói thành viên mới",
        TITLE_UPDATE_VIEW: "Cập nhật gói thành viên",
        TITLE_DETAIL_VIEW: "Xem chi tiết gói thành viên",
    },

    CASH_BOOK: {
        TITLE: "Sổ quỹ",
        CREATE_RECEIVE_VOUCHER: "Tạo phiếu thu",
        CREATE_PAYMENT_VOUCHER: "Tạo phiếu chi",
        TYPE: "Loại phiếu",
        STATUS: "Trạng thái",
        CODE: "Mã phiếu",
        PARTNER_NAME: "Tên NV/KH/NCC",
        CREATE_TIME: "Ngày ghi nhận",
        CREATE_USER: "Người tạo",
        PAYMENT_TYPE: "Phương thức thanh toán",
        MONEY: "Số tiền",
        CURRENCY: "ĐVTT",
        DESCRIPTION: "Diễn giải",
        RECEIVE: "Phiếu thu",
        PAYMENT: "Phiếu chi",
        ALL: "Tất cả",
        REFERENCE_DOCUMENT: "Chứng từ tham chiếu",
        STAFF_NAME: "Tên nhân viên",
        CUSTOMER: "Khách hàng/NCC",
        VIEW_REPORT: "Xem báo cáo",
        FROM_DATE: "Từ ngày",
        TO_DATE: "Đến ngày",
        // THIS_RESULT_IS_RECORDED_ON_A_MONTHLY_BASIS: "Kết quả này được ghi nhận theo tháng",
        // BEGINNING_MONEY: "Tiền đầu kỳ",
        // ENDING_MONEY: "Tiền cuối kỳ",
        // TOTAL_REVENUE: "Tổng thu",
        // TOTAL_EXPENDITURE: "Tổng chi",
    },

    RECEIVE_VOUCHER: {
        TITLE: "Tạo mới phiếu thu",
        TITLE_DETAIL_VIEW: "Thông tin chung",
        TITLE_UPDATE_VIEW: "Cập nhật phiếu thu",
        TITLE_DETAIL: "Thông tin phiếu thu",
        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        OTHER: "Đối tác",
        CODE: "Mã phiếu thu",
        REVENUE_RECOGNITION: "Nhóm người nộp",
        PAYER: "Tên người nộp",
        VOUCHER_CODE: "Mã phiếu",
        RECEIVE_TYPE: "Loại phiếu thu",
        RECEIVE_VALUE: "Giá trị ghi nhận",
        MONEY: "Số tiền",
        PAYMENT_METHOD: "Phương thức thanh toán",
        DESCRIPTION: "Diễn giải",
        BANK: "Ngân hàng nộp",
        ACCOUNT: "Tài khoản nộp",
        IS_DEFAULT: "Ghi nhận vào kết quả hoạt động kinh doanh",
        SELF_CREATED: "Tự tạo",
        AUTOMATIC: "Tạo tự động",
        CUSTOMER_ORDER: "Khách hàng đặt cọc",
        COLLECTION_AND_TRANSFER: "Thu nợ đối tác vận chuyển",
        HISTORY_UPDATE: "Lịch sử chỉnh sửa",
        RECEIVE_CONFIRM_SAVE: "Bạn có chắc chắn hoàn tất phiếu thu?",
    },

    PAYMENT_TICKET: {
        TITLE_CREATE: "Tạo mới phiếu chi",
        TITLE_UPDATE: "Cập nhật phiếu chi",
        TITLE_DETAIL: "Thông tin phiếu chi",
        CODE: "Mã phiếu chi",
        BACSIC_INFO: "Thông tin chung",
        RECIPIENT_GROUP: "Nhóm người nhận",
        SELECT_RECIPIENT_GROUP: "Chọn nhóm người nhận",
        RECIPIENT_NAME: "Tên người nhận",
        ENTER_RECIPIENT_NAME: "Nhập tên người nhận",
        TYPE_PAYMENT: "Loại phiếu chi",
        SELECT_TYPE_PAYMENT: "Chọn loại phiếu chi",
        RECOGNIZED_VALUE: "Giá trị ghi nhận",
        MONEY: "Số tiền",
        ENTER_MONEY: "Nhập số tiền",
        PAYMENT_METHODS: "Phương thức thanh toán",
        SELECT_PAYMENT_METHODS: "Chọn phương thức thanh toán",
        ACCOUNT_GET: "Tài khoản nhận",
        ENTER_ACCOUNT_GET: "Nhập tài khoản nhận",
        BANK_NAME: "Ngân hàng nhận",
        ENTER_BANK_NAME: "Nhập ngân hàng nhận",
        EXPLAIN: "Diễn giải",
        ENTER_EXPLAIN: "Nhập diễn giải",
        SELF_CREATED: "Tự tạo",
        AUTOMATIC: "Tạo tự động",
        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        OTHER: "Đối tác",
        STAFF: "Nhân viên",
        CONFIRM_COMPLETE: "Bạn có chắc muốn hoàn thành phiếu chi này?",
        IS_DEFAULT: "Ghi nhận vào kết quả hoạt động kinh doanh",
        HISTORY_UPDATE: "Lịch sử chỉnh sửa",
        COMMIT: "xác nhận thanh toán",
        CREATE_AUTO: "Tạo tự động",
        UPDATED: "Đã chỉnh sửa",
        INVOICE_DATE: "Ngày chứng từ",
        SELECT_INVOICE_DATE: "Chọn ngày chứng từ",
    },

    SERVICE_ORGANIZATION: {
        TITLE_MENU: "Quản lý tổ chức",
        TITLE_LIST_VIEW: "Danh sách tổ chức",
        ADD_ORGANIZATIONS: "Thêm tổ chức",
        ADD_USERS: "Thêm người dùng",
        ADD_NEW_ORGANIZATION: "Thêm mới tổ chức",
    },

    BUNDLE_PRODUCT: {
        TITLE_MENU: "Quản lý gói sản phẩm",
        TITLE_LIST: "Danh sách gói sản phẩm",
        TITLE_CREATE_VIEW: "Thêm gói sản phẩm mới",
        TITLE_UPDATE_VIEW: "Cập nhật gói sản phẩm",
        TITLE_DETAIL_VIEW: "Xem chi tiết gói sản phẩm",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên gói sản phẩm",
    },

    SUPPLIER: {
        TITLE_MENU: "Quản lý nhà sản xuất",
        TITLE_LIST: "Danh sách nhà sản xuất",
        TITLE_CREATE_VIEW: "Thêm nhà sản xuất mới",
        TITLE_UPDATE_VIEW: "Cập nhật nhà sản xuất",
        TITLE_DETAIL_VIEW: "Chi tiết nhà sản xuất",
        BASIC_INFO: "Thông tin nhà sản xuất",
    },

    SPONSOR_PROGRAM: {
        TITLE_MENU: "Chương trình tài trợ",
        TITLE_LIST_VIEW: "Danh sách chương trình",
        TITLE_CREATE_VIEW: "Tạo mới chương trình",
        TITLE_UPDATE_VIEW: "Cập nhật chương trình",
        TITLE_DETAIL_VIEW: "Chi tiết chương trình",
        PROGRAM_INFO: "Thông tin chương trình",
        PROGRAM_NAME: "Tên chương trình",
        PROGRAM_TYPE: "Loại chương trình",
        SPONSORSHIP: "Tài trợ",
        SCHOLARSHIP: "Học bổng",
    },

    PARTNER: {
        AGENT: "Đại lý",
        EXPERT: "Chuyên gia",
        SPONSOR: "Nhà tài trợ",
        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        MANUFACTURER: "Nhà sản xuất",
        REFERRAL: "Người giới thiệu",
    },
    AFFILIATE_PROGRAM: {
        TITLE_MENU: "Chương trình liên kết giới thiệu",
        TITLE_LIST_VIEW: "Danh sách chương trình liên kết giới thiệu",
        TITLE_CREATE_VIEW: "Tạo mới chương trình liên kết giới thiệu",
        TITLE_UPDATE_VIEW: "Cập nhật chương trình liên kết giới thiệu",
        TITLE_DETAIL_VIEW: "Chi tiết chương trình liên kết giới thiệu",
    },

    PROGRAM_AFFILIATE: {
        TITLE_MENU: "Liên kết giới thiệu",
        TITLE_LIST_VIEW: "Danh sách liên kết giới thiệu",
        TITLE_CREATE_VIEW: "Tạo mới liên kết giới thiệu",
        TITLE_UPDATE_VIEW: "Cập nhật liên kết giới thiệu",
        TITLE_DETAIL_VIEW: "Chi tiết liên kết giới thiệu",
    },

    PARTNER_COMMISSION: {
        TITLE_MENU: "Hoa hồng cộng tác viên",
        TITLE_LIST: "Danh sách hoa hồng cộng tác viên",
        TITLE_DETAIL_VIEW: "Chi tiết hoa hồng cộng tác viên",
        TITLE_CREATE_VIEW: "Tạo hoa hồng cộng tác viên",
    },

    SERVICE_FEE_SETTING: {
        TITLE_MENU: "Cấu hình phí dịch vụ",
        TITLE_LIST: "Danh sách phí dịch vụ",
        TITLE_CREATE_VIEW: "Tạo mới phí dịch vụ",
        TITLE_UPDATE_VIEW: "Cập nhật phí dịch vụ",
        TITLE_DETAIL_VIEW: "Chi tiết phí dịch vụ",
    },

    TICKET_FUND: {
        TITLE_MENU: "Quỹ xuất vé",
    },

    LIMIT_SETTING: {
        TITLE_MENU: "Cài đặt hạn mức",
        TITLE_LIST_VIEW: "Danh sách hạn mức",
        TITLE_CREATE_VIEW: "Thêm hạn mức mới",
        TITLE_UPDATE_VIEW: "Cập nhật hạn mức",
        TITLE_DETAIL_VIEW: "Thông tin hạn mức",
    },

    APPROVE_DEPOSIT: {
        TITLE_MENU: "Duyệt nạp tiền",
        TITLE_LIST_VIEW: "Duyệt nạp tiền",
        TITLE_CREATE_VIEW: "Tạo mới nạp tiền",
        TITLE_UPDATE_VIEW: "Cập nhật nạp tiền",
        TITLE_DETAIL_VIEW: "Thông tin nạp tiền",
    },

    REQUEST_DEPOSIT: {
        TITLE_MENU: "Yêu cầu nạp tiền",
        TITLE_LIST_VIEW: "Yêu cầu nạp tiền",
        TITLE_CREATE_VIEW: "Tạo mới nạp tiền",
        TITLE_UPDATE_VIEW: "Cập nhật nạp tiền",
        TITLE_DETAIL_VIEW: "Thông tin nạp tiền",
    },
};
